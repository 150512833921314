@import "../../../variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "../../../utilities/globalmixins";

.image-tile {
    padding: 0 1.25rem;

    @include media-breakpoint-down(sm) {
        padding: 0 0.5rem;
    }

    h3 {
        margin: 1rem 0;
        font-weight: 600;
    }

    img {
        margin: auto;
    }
}

// displays half tile at the right
.slick-list {
    padding: 0 15% 0 0 !important;
}

// includes arrounded styled slick arrow
@include roundedSlickArrow;
